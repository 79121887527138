import React from 'react'
import PropTypes from 'prop-types'

import {navigate} from 'gatsby'

import Layout from 'components/Layout'

class IndexPage extends React.Component {
  static propTypes = {
    navigate: PropTypes.func.isRequired,
    location: PropTypes.object.isRequired,
    pageContext: PropTypes.object.isRequired
  }

  componentDidMount () {
    const {pageContext: {langKey}} = this.props

    navigate(`/${langKey}/politis/docs/get-started`, {replace: true})
  }

  render () {
    const {navigate, location} = this.props

    return (
      <Layout
        fluid
        sidebar={<div />}
        navigate={navigate}
        location={location}
      />
    )
  }
}

export default IndexPage
